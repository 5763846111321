@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  min-height: 100vh;
  background: #f5f5f5;
}

a {
  text-decoration: none;
}

.ymaps-2-1-79-map-copyrights-promo,
.ymaps-2-1-79-copyright {
  display: none !important;
}
